import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import { borderRounded } from '../../styles/radius'

export const WealthSlider = styled.div`
  max-width: 96%;
  margin: 0 auto;

  .carousel {

    .slider-wrapper {
      min-height: 250px;

      @media (min-width: ${breakpoints.md}) {
        min-height: 210px;
      }
      
      ul {
        li.slide {
          background: none;

          img {
            width: 150px;
          }

          > div {
            height: 100%;
          
            .card {
              display: flex;
              flex-direction: column;
              justify-content: center;
              min-height: 180px;

              @media (min-width: ${breakpoints.md}) {
                min-height: 130px;
              }
            }
          }
        }
      }
    }

    .control-dots {
      .dot {
        border-radius: ${borderRounded['4']};
        box-shadow: none;
        height: 8px;
        outline: none;
        width: 8px;

        &.selected {
          width: 8px;
        }
      }
    }
  }
`
export const FundSliderButton = styled.button`
  border: none;
  outline: none;
  background: none;
  position: absolute;
  bottom: 0;
  z-index: 1;

  &:active,
  &:focus {
    outline: none;
  }

  &.button-left {
    left: 0;

    @media (min-width: ${breakpoints.md}) {
      left: 30%;
    }
  }
  &.button-right {
    right: 0;

    @media (min-width: ${breakpoints.md}) {
      right: 30%;
    }
  }
`
