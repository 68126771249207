import React from 'react'

// Components
import WealthSlider from '../../../components/WealthSlider'
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'

// Assets
import LogoWin from '../../../images/logo-win.svg'

// Types
type DataItems = {
  title?: string | null;
  description: string;
  image?: string | null;
}

type DataProps = {
  data: DataItems[];
}

const Solucoes = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='bg-green-200 py-5'>
      <div className='container'>
        <header className='text-center'>
          <h2 className='f-sora fs-16 lh-20 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-46 text-blue-900 d-block fw-600 pb-lg-2'>
            Soluções em Wealth Management
          </h2>
          <p className='fs-12 lh-15 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 fs-xl-20 lh-xl-24 fw-400 fw-md-700 mb-4'>
            Conte com a expertise e os serviços da Inter Asset para alcançar seus objetivos
          </p>
        </header>
        <div className='row justify-content-center text-center'>
          <WealthSlider>
            <div className='col-11 col-lg-9 col-xl-7 bg-white rounded-8 mx-auto py-3 text-center card'>
              <h3 className='text-gray-800 fs-16 lh-20 fw-600'>Método Inter Asset</h3>
              <p className='text-gray-700 fs-14 lh-18 mb-0'>
                Trabalhamos para atender quem busca segurança e rentabilidade na gestão de ativos. Temos soluções ideais para quem deseja gerir seu patrimônio de forma profissional e segura.
              </p>
            </div>
            <div className='col-11 col-lg-9 col-xl-7 bg-white rounded-8 mx-auto py-3 text-center card'>
              <h3 className='text-gray-800 fs-16 lh-20 fw-600'>Gestão de investimentos</h3>
              <p className='text-gray-700 fs-14 lh-18 mb-0'>
                Entendemos a importância de cada tipo de estratégia e selecionamos os ativos e fundos de gestores especialistas em suas classes de acordo com o seu perfil, utilizando os modelos mais atuais de Asset Allocation e Gestão de Risco.
              </p>
            </div>
            <div className='col-11 col-lg-9 col-xl-7 bg-white rounded-8 mx-auto py-3 text-center card'>
              <h3 className='text-gray-800 fs-16 lh-20 fw-600'>Monitoramento contínuo</h3>
              <p className='text-gray-700 fs-14 lh-18 mb-0'>
                Acompanhamos sua carteira diariamente, buscando agir frente às oportunidades e riscos do mercado.
              </p>
            </div>
            <div className='col-11 col-lg-9 col-xl-7 bg-white rounded-8 mx-auto py-3 text-center card'>
              <h3 className='text-gray-800 fs-16 lh-20 fw-600'>Segurança</h3>
              <p className='text-gray-700 fs-14 lh-18 mb-0'>
                Seus investimentos permanecem custodiados a todo momento em instituições financeiras de primeira linha.
              </p>
            </div>
            <div className='col-11 col-lg-9 col-xl-7 bg-white rounded-8 mx-auto py-3 text-center card'>
              <figure className='mx-auto mb-3'>
                <img src={LogoWin} alt='Win' title='Win' />
              </figure>
              <p className='text-gray-700 fs-14 lh-18 mb-0'>
                O acesso aos produtos da Inter Asset relacionados à gestão de patrimônio é feito por meio do WIN  – Inter Wealth Management.
                <a
                  href={process.env.SITE_WIN}
                  target='_blank'
                  rel='noreferrer'
                  className='text-brown-base'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      section_name: 'Soluções em Wealth Management',
                      element_name: 'Conheça o WIN',
                      element_action: 'click button',
                      redirect_url: process.env.SITE_WIN,
                    })
                  }}
                >
                  Conheça o WIN
                </a>
              </p>
            </div>
          </WealthSlider>
        </div>
      </div>
    </section>
  )
}

export default Solucoes
