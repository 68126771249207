import React, { ReactChild, MouseEvent } from 'react'
import { Carousel } from 'react-responsive-carousel'

// Components
import Icon from '../Icon'

// Style
import { WealthSlider, FundSliderButton } from './style'

interface IData {
  title: string;
  description: string;
}

interface IWealthSlider {
  children: ReactChild[];
}

const WealthSliderComponent: React.FC<IWealthSlider> = ({ children }: IWealthSlider): React.ReactElement => {
  function renderIndicator (onClickHandler: (e: MouseEvent) => void, isSelected: boolean, index: number, label: string) {
    return (
      <li
        className={`dot ${isSelected ? 'selected' : ''}`}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role='button'
        tabIndex={0}
        aria-label={`slide item ${index}`}
        data-slide-solucoes={`Slide ${index + 1}`}
      />
    )
  }

  const renderArrowPrev = (onClickHandler: () => void, hasPrev: boolean) => (
    hasPrev && (
      <FundSliderButton onClick={onClickHandler} className='button-left'>
        <Icon width={40} height={40} directory='v2' icon='navigation/arrow-left' color='white' />
      </FundSliderButton>
    )
  )

  const renderArrowNext = (onClickHandler: () => void, hasNext: boolean) => (
    hasNext && (
      <FundSliderButton onClick={onClickHandler} className='button-right'>
        <Icon width={40} height={40} directory='v2' icon='navigation/arrow-right' color='white' />
      </FundSliderButton>
    )
  )

  return (
    <WealthSlider>
      <Carousel
        showArrows={true}
        renderArrowPrev={renderArrowPrev}
        renderArrowNext={renderArrowNext}
        autoPlay
        infiniteLoop
        transitionTime={800}
        showThumbs={false}
        showStatus={false}
        interval={12000}
        renderIndicator={renderIndicator}
      >
        { children.map((item: ReactChild, index: number) => <div key={index}>{item}</div>) }
      </Carousel>
    </WealthSlider>
  )
}

export default WealthSliderComponent
